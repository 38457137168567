import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { pxToRem } from '../utils/pxToRem';

import { ContentfulContact, ContentfulTeam, ContentfulTeamMember, Maybe } from '../model/generated/graphql';
import { Avatar, Member } from '../modules/crew/Member';
import { NavLogo } from '../components/Navigation';

type ContactPageProps = {
    data: {
        contentfulContact: ContentfulContact;
    };
};

export const Container = styled.div`
    width: 100%;
    max-width: ${pxToRem(1440)};
    margin: 0 auto;
`;

export const Row = styled.div`
    padding: 0 ${pxToRem(23)};
`;

const LazyGoogleMap = React.lazy<FC>(() => import('../components/LazyGoogleMap/LazyGoogleMap').then((mod) => mod));

const ContactPage: FC<ContactPageProps & PageProps> = ({ data, location }) => {
    const page = data.contentfulContact;

    const roles = {
        owners: [],
        production: [],
        artists: [],
        authors: [],
    };

    const isSSR = typeof window === 'undefined';

    return (
        <div>
            <Container>
                <Row>
                    <HeroWrapper>
                        <HeroTitleWrapper>
                            <HeroTitle>{page.title}</HeroTitle>
                            <HeroSubtitle>
                                {page.subtitle1} <br /> {page.subtitle2}
                            </HeroSubtitle>
                        </HeroTitleWrapper>
                    </HeroWrapper>
                </Row>
            </Container>

            <Container>
                <Row>
                    <ContactsContainer>
                        {page.contactPersons.map((el) => {
                            return <ContactItem data={el} key={el.email} />;
                        })}
                    </ContactsContainer>
                </Row>
            </Container>

            <MapSection>
                {!isSSR && (
                    <React.Suspense fallback={<div />}>
                        <LazyGoogleMap />
                    </React.Suspense>
                )}

                <Container>
                    <HeadquartersWrapper>
                        <ContactInner>
                            <ContactLogo>
                                <StaticImage
                                    src="../images/logo-no-padding.png"
                                    alt=""
                                    placeholder="blurred"
                                    style={{ marginTop: '50px' }}
                                    loading={'eager'}
                                />
                            </ContactLogo>
                            <div style={{ width: '100%', textAlign: 'right', marginTop: 40 }}>
                                <AddressLineLink href={`mailto:${page.companyEMail}`}>
                                    {page.companyEMail}
                                </AddressLineLink>
                                <AddressLine>{page.addressStreet}</AddressLine>
                                <AddressLine>
                                    {page.addressZipCode} {page.addressCity}
                                </AddressLine>
                            </div>
                        </ContactInner>
                    </HeadquartersWrapper>
                </Container>
            </MapSection>
        </div>
    );
};

export default ContactPage;

const AddressLine = styled.p`
    font-size: 1.5rem;
    line-height: 1;
    @media (max-width: ${pxToRem(800)}) {
        font-size: 1rem;
        line-height: 1;
    }
`;
const AddressLineLink = styled.a`
    color: #fff;
    font-size: 1.5rem;
    line-height: 1;
    @media (max-width: ${pxToRem(800)}) {
        font-size: 1rem;
        line-height: 1;
    }
    &:hover {
        text-shadow: 0 0 2px #fff;
        &::after {
            opacity: 0;
        }
    }
`;

const ContactLogo = styled.div`
    max-width: ${pxToRem(400)};
    @media (max-width: ${pxToRem(500)}) {
        max-width: ${pxToRem(200)};
    }
`;

const ContactItem: FC<{ data: ContentfulTeamMember }> = ({ data }) => {
    const image = getImage(data.avatar.gatsbyImageData);
    return (
        <StyledContactItem>
            <ContactInner>
                <Avatar alt="" image={image} />
            </ContactInner>
            <ContactInner>
                <h3>{data.name}</h3>
                <ContactLink href={`mailto:${data.email}`}>{data.email}</ContactLink>
                <ContactLink href={`tel:${data.phone}`}>{data.phone}</ContactLink>
            </ContactInner>
        </StyledContactItem>
    );
};

const HeadquartersWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    z-index: 1;
    font-family: sans-serif;

    & img {
        margin-right: -30px;
    }

    & h3 {
        font-size: 3rem;
    }
`;

const MapSection = styled.div`
    display: flex;
    margin-bottom: 100px;
    position: relative;
    align-content: flex-end;
`;

const ContactsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 200px;
`;

const StyledContactItem = styled.div`
    display: flex;
    justify-items: center;
    align-items: center;
    margin: ${pxToRem(15)};

    & h3 {
        margin-top: 0;
        margin-bottom: 6px;
    }
`;

const ContactInner = styled.div`
    padding: 0 ${pxToRem(12)};
`;

const ContactLink = styled.a`
    color: var(--color-text-primary);
    display: block;
    line-height: 2;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 50%;
        height: 1px;
        background-color: white;
        opacity: 0;
        transition: opacity ease-in-out 0.5s;
    }
    &:hover {
        text-shadow: 0 0 2px #fff;
        &::after {
            opacity: 0;
        }
    }
`;

export const HeroTitle = styled.h1`
    font-size: 9rem;
    text-align: center;
    margin-bottom: 10px;
    @media (max-width: ${pxToRem(1200)}) {
        font-size: 7rem;
        text-align: left;
    }
    @media (max-width: ${pxToRem(800)}) {
        font-size: 5rem;
    }

    @media (max-width: ${pxToRem(480)}) {
        font-size: 3rem;
    }
`;

const HeroSubtitle = styled.h2`
    font-size: 2rem;
    line-height: 1;
    margin: 0 0 8px;
    white-space: pre-line;
    @media (max-width: ${pxToRem(480)}) {
        font-size: 1.5rem;
    }
`;

const HeroWrapper = styled.div`
    height: calc(100vh - 400px);
    display: flex;
    align-items: center;
    @media (max-width: ${pxToRem(800)}) {
        height: 40vh;
    }
    //
    // @media (max-width: ${pxToRem(480)}) {
    //     height: 0vh;
    // }
`;

const HeroTitleWrapper = styled.div``;

export const pageQuery = graphql`
    query ContactQuery($node_locale: String) {
        contentfulContact(node_locale: { eq: $node_locale }, contentful_id: { eq: "3iwd4ekj0olGAj1i0ocHrd" }) {
            title
            subtitle1
            subtitle2
            contactPersons {
                id
                name
                role
                avatar {
                    gatsbyImageData(width: 100, height: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
                }
                email
                phone
            }
            addressCity
            addressStreet
            addressZipCode
            companyEMail
            companyName
        }
    }
`;
